<script>
import { fakeData } from "./fakeData";
import { updateUnit } from '@/utils/apis.js';

export default {
  page: {
    title: "Units Table",
  },
  components: {},
  data() {
    return {
      tableData: [],
      fakeData,
      title: "Advanced",
      items: [
        {
          text: "Tables",
        },
        {
          text: "Advanced",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 20, 50],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "unit",
          sortable: false,
        },
        {
          key: "permanent_no",
          sortable: false,
        },
        {
          key: "status",
          sortable: false,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.setTableData();
    this.currentPage = this.pageNumber
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    useOpenDeletePopup(id,index){
        this.openDeletePopup(id,index);
    },
    setTableData(){
          this.tableData = this.fakeData.map(item => ({...item, isEdit: false}));
    },
    async editRowHandler(data) {
        this.unitsTableData[data.index].isEdit = !this.unitsTableData[data.index].isEdit;
        console.log(this.unitsTableData[data.index]);
        if(!this.unitsTableData[data.index].isEdit){
          try {
            const res = await updateUnit(this.unitsTableData[data.index].id,{
            status: this.unitsTableData[data.index].status,
            permanent_unit_name: this.unitsTableData[data.index].permanent_no,
            temp_unit_name: this.unitsTableData[data.index].unit,
          });
          console.log(res);
          this.$toasted.success("Unit updated successfully", {
            position: "top-center",
            theme: "bubble",
            duration: 3000,
            action: {
              text: "Close",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          });

          } catch (error) {
            this.$toasted.error("Error updating unit", {
              position: "top-center",
              theme: "bubble",
              duration: 3000,
              action: {
                text: "Close",
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                },
              },
            });
          }
        }
    }
  },
  watch:{
    pageNumber(val){
      console.log("PGUA", val);
      // if(this.pageNumber != this.currentPage){
      //   this.currentPage = this.pageNumber;
      // }
    },
    currentPage(val){
      console.log("pageChanged", val);
      if(this.pageNumber != this.currentPage){
        this.setPageNumber(this.currentPage)
      }
    }
  },
  props:["openDeletePopup","unitsTableData","rows", "pageNumber","setPageNumber"],
  middleware: "authentication",
};
</script>

<template>
  <div class="row mt-4">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">List of Units</h4>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="unitsTableData ? unitsTableData :[]"
              :fields="fields"
              responsive="sm"
              :per-page="0"
              :current-page="currentPage"
            >
                <template #cell(unit)="data">
                    <b-form-input v-if="unitsTableData[data.index].isEdit" type="text" v-model="unitsTableData[data.index].unit"></b-form-input>
                    <span v-else>{{ data.value }}</span>
                </template>
                <template #cell(permanent_no)="data">
                    <b-form-input v-if="unitsTableData[data.index].isEdit" type="text" v-model="unitsTableData[data.index].permanent_no"></b-form-input>
                    <span v-else>{{ data.value }}</span>
                </template>
                <template #cell(status)="data">
                    <b-form-select v-if="unitsTableData[data.index].isEdit" v-model="unitsTableData[data.index].status" :options="['Fully Occupied','Partly Occupied','Vacant']" class="form-control"></b-form-select>
                    <span v-else>
                        <span
                        class="badge font-size-12 text-center"
                        :class="{
                            'bg-soft-success': data.value === 'Partly Occupied',
                            'bg-soft-danger': data.value === 'Fully Occupied',
                            'bg-soft-warning': data.value === 'Vacant',
                        }"
                            >{{ unitsTableData[data.index].status }}</span
                        >
                    </span>
                </template>
              <template #cell(actions)="data">
                  <div>
                  <i v-if="!unitsTableData[data.index].isEdit" @click="editRowHandler(data)" class="fas fa-pencil-alt" style="color:#5B73E8;cursor:pointer;"></i>
                  <i  v-else class="far fa-check-circle" @click="editRowHandler(data)" style="color:#5B73E8;cursor:pointer;"></i>
                  <i class="fas fa-trash-alt" style="color:#F46A6A;cursor:pointer;margin-left:1rem;" @click="useOpenDeletePopup(data.item.id,1)"></i>
                  </div>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="10"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
