export const fakeData = [{
        id: 1,
        unit: 123,
        permenant_no: 123,
        type: "Occupied",
        status: "Active",
    },
    {
        id: 2,
        unit: 123,
        permenant_no: 123,
        type: "Occupied",
        status: "Active",

    },
    {
        id: 3,
        unit: 123,
        permenant_no: 123,
        type: "Empty",
        status: "Inactive",
    },
    {
        id: 4,
        unit: 123,
        permenant_no: 123,
        type: "Occupied",
        status: "Active",
    },
    {
        id: 5,
        unit: 123,
        permenant_no: 123,
        type: "Partly Occupied",
        status: "Active",
    },
    {
        id: 6,
        unit: 123,
        permenant_no: 123,
        type: "Occupied",
        status: "Inactive",
    },


]