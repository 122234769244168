<template>
  <Home>
    <Preloader v-if="loader" />
    <div class="w-100" v-if="!isEmpty">
      <button type="button" class="btn btn-info" v-b-modal.modal-2>
        <span>+</span> Add Units
      </button>
      <b-modal
        class="main-modal"
        id="modal-2"
        title="Add Unit"
        ok-title="Add"
        @ok="onSubmit"
      >
        <div class="modal-ele">
          <label for="category"><strong>Building Name</strong></label>
          <multiselect
            v-model="tempBuilding"
            :options="tempBuildingsData"
            track-by="id"
            label="name"
            placeholder="Select Building"
            class="helo"
          ></multiselect>
          <label for="tenant"><strong>Status</strong></label>
          <multiselect
            v-model="status"
            :options="options"
            class="helo"
          ></multiselect>
          <label for="permanent_no"><strong>Unit Name</strong></label>
          <input
            type="text"
            class="form-control"
            placeholder="Unit Name"
            v-model="permanent_no"
          />
          <label for="no_of_beds"><strong>Number of Beds</strong></label>
          <input
            type="number"
            class="form-control"
            placeholder="Number of beds"
            v-model="no_of_beds"
          />
        </div>
      </b-modal>
      <div style="width:20rem; margin-top:1rem;">
       <div>
          <label for="invoice"><strong>Select Building</strong></label>
       </div>
        <multiselect
        v-model="selectedBuilding"
        :options="buildingsData"
        track-by="id"
        label="name"
        placeholder="Select one"
        class="helo"
      ></multiselect>
     </div>
      <div class="d-flex flex-column justify-content-start position-relative">
        <UnitsTable :rows="rows" :unitsTableData="unitsTableData" :setPageNumber="setPageNumber" :pageNumber="pageNumber" />
      </div>
    </div>
    <div v-if="isEmpty">
      <div style="height:80vh;" class="d-flex flex-column align-items-center justify-content-center">
        <h3 class="mb-3">No Units Found</h3>
        <div>
          <button type="button" class="special-button btn btn-info" v-b-modal.modal-2>
            <span>+</span> Add Units
          </button>
        </div>
      </div>
    </div>
  </Home>
</template>
<script>
import UnitsTable from "@/new-components/units-table/UnitsTable.vue";
import Home from "@/new-views/Home/Home.vue";
import { getUnits } from "@/utils/apis.js";
import { createUnit, getallbuilding } from "@/utils/apis.js";
import Multiselect from "vue-multiselect";
import Preloader from "@/new-components/Preloader/Preloader.vue";
import Vue from "vue";
import axios from "axios";
Vue.use(axios);
export default {
  data() {
    return {
      rows:"",
      loader: true,
      unitsTableData: [],
      id: "",
      unit: "",
      permanent_no: "",
      status: "",
      options: ["Fully Occupied","Partially Occupied","Vacant"],
      isEmpty: false,
      buildingsData: [{}],
      selectedBuilding: {},
      pageNumber:1,
      tempBuildingsData: [],
      tempBuilding: {},
      no_of_beds: "",
    };
  },
  methods: {
    async useUnitstable() {
      let body = {
        limit: 10,
        offset: (this.pageNumber - 1) * 10,
        building:"",
      }
      if(this.selectedBuilding.id){
        body.building =this.selectedBuilding.id;
      }
      const result = await getUnits(body);
      this.rows = result.count; 
      this.loader = false;
      this.unitsTableData = result.results
        ? this.formatTabledata(result.results)
        : [];
      console.log(result);
      if(result.results.length == 0){
        this.isEmpty = true;
      }
    },
    formatTabledata(data) {
      let array = [];
      data.forEach((element) => {
        array.push({
          id: element.id,
          unit: element.temp_unit_name,
          status: element.status,
          permanent_no: element.permanent_unit_name,
          isEdit: false,
        });
      });
      return array;
    },

    async onSubmit(e) {
      e.preventDefault();
      const body = {
        building: this.tempBuilding.id,
        permanent_unit_name: this.permanent_no,
        status: this.status,
        beds: parseInt(this.no_of_beds),
      };
      let res = await createUnit(body);
      console.log(res);
      if (this.tempBuilding.id || this.permanent_no || this.status || this.no_of_beds) {
        this.$toasted.error("Please fill all details", {
          position: "top-center",
          duration: 3000,
        });
      } else {
        if (res.success) {
          this.$toasted.success("Collection saved successfully", {
            position: "top-center",
            duration: 3000,
          });
        } else {
          this.$toasted.error("Data couldnot sent", {
            position: "top-center",
            duration: 3000,
          });
        }
      this.clearForm();
      }
      this.unitsTableData;
    },

    async useGetallBuilding(){
      const response = await getallbuilding();
      this.loader= false;
      response.buildings
        ? this.buildingsData = [...this.formatbuildingsdata(response.buildings)]
        : [];
      response.buildings
        ? this.tempBuildingsData = [...this.formatbuildingsdata(response.buildings)]
        : [];
      console.log(response);
      if(response.buildings.length === 0){
        this.isEmpty = true;
      }
      else{
        // this.selectedBuilding = this.buildingsData[0];
        this.useUnitstable();
      }
    },
    formatbuildingsdata(data) {
    let array = [];
    data.forEach((element) => {
      array.push({
        id: element.id,
        name:element.building_name,
        number_of_units:element.no_of_units,
        number_of_tenants:element.owner,
        image:element.image_url,
        total_earnings:element.earnings.amount__sum,
      });
    });
    console.log(array);
    return array;
    },
    clearForm() {
      this.id = "";
      this.unit = "";
      this.status = "";
      this.permanent_no = "";
    },
    setPageNumber(pageNumber) {
      this.pageNumber = pageNumber;
      this.useUnitstable();
    },
  },
  components: {
    UnitsTable,
    Home,
    Preloader,
    Multiselect,
  },
  mounted() {
    // this.useUnitstable();
    this.useGetallBuilding();
  },
  watch: {
    selectedBuilding(newVal) {
      if(newVal.id === ""){
        this.useUnitstable();
        console.log("All Buildings");
      }else{
        console.log("IIJJ",newVal.id);
        if(this.pageNumber != 1){
          this.pageNumber = 1;
        }else{
          this.useUnitstable(newVal.id, 1);
        }
      }
    },
    pageNumber: function (newVal){
      console.log(newVal);
      this.useUnitstable();
    },
  }
};
</script>
<style lang="scss" scoped>
.modal-ele {
  display: flex;
  flex-direction: column;
}
title {
  font-weight: 100;
}

.main-modal {
  position: absolute;
  width: 613px;
  height: 649px;
  left: 424px;
  top: 125px;

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.special-button {
  height: 4rem;
  width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}
</style>